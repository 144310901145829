<template>
  <v-carousel
    class="mb-4"
    cycle
    :hide-delimiters="true"
    :show-arrows="itemsLength > 1 ? true : false"
    height="auto"
  >
    <template>
      <v-carousel-item v-for="(item, i) in items" :key="i">
        <div class="d-flex flex-column align-center">
          <img :src="item.img" :alt="item.name" class="item-img mb-2" />

          <div class="item-name">{{ item.name }}</div>
          <div class="font-weight-bold mb-2">
            {{ item.price }} {{ $store.getters['app/currency'] }}
          </div>

          <v-btn
            v-if="item.has_modifier"
            depressed
            color="primary"
            @click="toDetailPage(item)"
            >Выбрать</v-btn
          >

          <v-btn v-else depressed color="primary" @click="addCartItem(item)"
            >Добавить</v-btn
          >
        </div>
      </v-carousel-item>
    </template>
  </v-carousel>
</template>

<script>
export default {
  props: {
    items: { type: [Array, Object], required: true },
    from: String,
  },

  methods: {
    async addCartItem(item) {
      const { commit, dispatch } = this.$store
      const { id, price } = item
      const params = { item_id: id, price, qty: 1, upsale: true, from: this.from}

      commit('overlay/update', { active: true })

      try {
        await dispatch('checkout/addItem', params)
        this.$emitter.emit('product-add')
      } catch (e) {
        commit('snackbar/update', {
          active: true,
          text: e.message,
        })
      } finally {
        commit('overlay/update', { active: false })
      }
    },

    toDetailPage({ id }) {
      this.$router.push(`/catalog/product/${id}?from=${this.from}`)
    },
  },

  computed: {
    itemsLength() {
      return Object.keys(this.items).length
    },
  },
}
</script>

<style lang="scss" scoped>
.item-img {
  height: 100px;
}

.item-name {
  font-weight: 600;
}
</style>
