<template>
  <v-carousel
    class="mb-2"
    cycle
    :hide-delimiters="true"
    :show-arrows="itemsLength > 1 ? true : false"
    height="auto"
  >
    <template>
      <v-carousel-item v-for="(item, i) in items" :key="i">
        <div class="d-flex flex-column align-center">
          <img :src="item.img" :alt="item.name" class="item-img mb-2" />

          <div class="item-name">{{ item.name }}</div>
          <div class="font-weight-bold mb-2">
            {{ item.price }} {{ $store.getters['app/currency'] }}
          </div>

          <v-btn
            v-if="item.has_modifier"
            class="select-btn"
            depressed
            color="primary"
            @click="toDetailPage(item)"
            >Выбрать</v-btn
          >

          <div v-else class="qty-box d-flex align-center mb-8">
            <v-btn
              outlined
              depressed
              x-small
              class="qty-button"
              color="secondary"
              @click="updateCartItem(item, -1)"
            >
              <v-icon color="dark">{{ icons.mdiMinus }}</v-icon>
            </v-btn>
            <span class="font-weight-bold">{{ item.qty }}</span>
            <v-btn
              outlined
              depressed
              x-small
              class="qty-button"
              color="secondary"
              @click="updateCartItem(item, +1)"
            >
              <v-icon color="dark">{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </div>
        </div>
      </v-carousel-item>
    </template>
  </v-carousel>
</template>

<script>
import { mdiMinus, mdiPlus } from '@mdi/js'

export default {
  props: {
    items: { type: [Array, Object], required: true },
    from: String
  },

  data: () => ({
    icons: {
      mdiMinus,
      mdiPlus,
    },
  }),

  methods: {
    async updateCartItem(item, value) {
      const { commit, dispatch } = this.$store
      const { id, price, qty, index } = item

      commit('overlay/update', { active: true })

      try {
        if (qty + value > 0) {
          await dispatch('checkout/addItem', { item_id: id, price, qty: value, upsale: true, from: this.from })
          this.$emitter.emit('product-add')
        } else if (qty) {
          await dispatch('checkout/removeItem', index)
        }
      } catch (e) {
        commit('snackbar/update', {
          active: true,
          text: e.message,
        })
      } finally {
        commit('overlay/update', { active: false })
      }
    },

    toDetailPage({ id }) {
      this.$router.push(`/catalog/product/${id}?from=${this.from}`)
    },
  },

  computed: {
    itemsLength() {
      return Object.keys(this.items).length
    },
  },
}
</script>

<style lang="scss" scoped>
.item-img {
  height: 100px;
}

.item-name {
  font-weight: 600;
}

.qty-box {
  gap: 30px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .qty-button {
    min-height: 47px;
  }
}

.select-btn {
  margin-bottom: 43px;
}
</style>
